<template>
  <b-card class="list-tickets-container">
    <filter-swapper :controlHeightButtons="controlHeight" class="mb-1">
       <template #slot1>
        <form-render :form.sync="form" :fields="fieldsForm" @send="getAllData('', true)" :key="keyFormRender">
          <template #advancedSearchSlot>
            <label></label>
            <div class="align-content-center justify-content-start tickets-list--buttons">
              <b-button variant="link" title="Búsqueda avanzada" class="p-0" v-b-modal.modalFilterAdvanced>
                <u>Búsqueda avanzada</u>
              </b-button>
            </div>
          </template>
          <template #buttonsGroupSlot>
            <label></label>
            <div class="align-content-center tickets-list--buttons">
              <div class="tickets-list--buttons--container">
                <b-button variant="warning" type="submit" title="Buscar"><feather-icon icon="SearchIcon" /></b-button>
                <b-button variant="outline-primary" @click="resetForm" title="Reiniciar filtros"><feather-icon icon="RefreshCwIcon" /></b-button>
              </div>
            </div>
          </template>
          <template #secondButtonsGroupSlot>
            <label></label>
            <div class="align-content-center tickets-list--buttons">
              <div class="tickets-list--buttons--container justify-content-end">
                <color-ticket-vue :colorArray="colorArray" @updateColorUser="setColorArray"></color-ticket-vue>
                <b-button variant="outline-warning" v-b-modal.idModalUpdateDocuments title="Subir archivos"><feather-icon icon="ArrowUpIcon" />Subir Archivo</b-button>
                <b-overlay :show="loading.exportFile" rounded="sm" variant="white" v-if="dataUser.role === 'admin'" >
                  <b-dropdown :disabled="loading.exportFile" variant="outline-success" toggle-class="text-decoration-none" title="Listado Ticket" right>
                    <template #button-content>
                      <feather-icon icon="ArrowDownIcon" />Listado Ticket
                    </template>
                    <b-dropdown-item
                      v-for="(element, index) in optionsListTicket"
                      :key="index"
                      @click="element.idModal ? $bvModal.show(element.idModal) : exportFile(element.value)">
                      {{element.text}}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-overlay>
                <b-button v-else @click="exportFile('all')" variant="outline-success" title="Listado Ticket">
                  <feather-icon icon="ArrowDownIcon" />Listado Ticket
                </b-button>
              </div>
            </div>
          </template>
        </form-render>
       </template>
    </filter-swapper>
    <div class="ticket--column--container">
      <div class="ticket--column">
        <b-button variant="link" class="ticket--column--list-button" @click="getAllData('issue', true)">
          <div>
            Problema
          </div>
        </b-button>
        <column-list @ticketId="showTicket" :listTicket="listTickets.issue" :shownOverlay.sync="shownOverlay.issue" :colorArray="optionsColorArray"></column-list>
      </div>
      <div class="ticket--column">
        <b-button variant="link" class="ticket--column--list-button" @click="getAllData('compensation', true)">
          <div>
            Reclamo de indemnización
          </div>
        </b-button>
        <column-list @ticketId="showTicket" :listTicket="listTickets.compensation" :shownOverlay.sync="shownOverlay.compensation" :colorArray="optionsColorArray"></column-list>
      </div>
      <div class="ticket--column">
        <b-button variant="link" class="ticket--column--list-button" @click="getAllData('disagreement', true)">
          <div>Discrepancia</div></b-button>
        <column-list @ticketId="showTicket" :listTicket="listTickets.disagreement" :shownOverlay.sync="shownOverlay.disagreement" :colorArray="optionsColorArray"></column-list>
      </div>
      <div class="ticket--column">
        <b-button variant="link" class="ticket--column--list-button" @click="getAllData('closed', true)"><div>Cerrado</div></b-button>
        <column-list @ticketId="showTicket" :listTicket="listTickets.closed" :shownOverlay.sync="shownOverlay.closed" :colorArray="optionsColorArray"></column-list>
      </div>
    </div>
    <modal-update-documents-vue></modal-update-documents-vue>
    <modal-ticket ref="modalTicketRef" :ticketId="ticketIdValue" @resetTicketId="resetTicketId"></modal-ticket>
    <modal-admin-mode-vue :loadingButton="loading.exportFile" @exportAdminMode="exportAdminMode"></modal-admin-mode-vue>
    <b-modal id="modalFilterAdvanced" size="xl" centered title="Búsqueda avanzada" ok-title="Filtrar" ok-only @ok="getAllData('', true)">
      <form-render :form.sync="form" :fields="fieldsFormAdvanced" :key="keyFormRender"></form-render>
    </b-modal>
  </b-card>
</template>
<script>
import BaseServices from '@/store/services/index'
import modalTicket from '@/views/modules/tickets/components/Modals/ModalTicket.vue'
import columnList from '@/views/modules/tickets/components/List/Column.vue'
import {
  BIcon,
  BIconFunnel,
  BIconFileArrowDown,
  BIconFileArrowUp
} from 'bootstrap-vue'
import modalUpdateDocumentsVue from '@/views/modules/tickets/components/Modals/modalUpdateDocuments.vue'
import modalAdminModeVue from '@/views/modules/tickets/components/Modals/modalCompensationMode.vue'
import ColorTicketVue from '@/views/modules/tickets/components/ColorTicket/ColorTicket.vue'
/* eslint-disable vue/no-unused-components */
export default {
  components: {columnList, BIcon, BIconFunnel, BIconFileArrowDown, BIconFileArrowUp, modalUpdateDocumentsVue, modalTicket, modalAdminModeVue, ColorTicketVue},
  data() {
    return {
      baseService: new BaseServices(this),
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      listTickets: [],
      keyFormRender: 0,
      shownOverlay: {
        closed: false,
        compensation: false,
        complaint: false,
        disagreement: false,
        issue: false
      },
      page: {
        closed: 1,
        compensation: 1,
        complaint: 1,
        disagreement: 1,
        issue: 1
      },
      form: {},
      fieldsForm: [],
      fieldsFormAdvanced: [],
      arrayCarrier: [],
      arrayCompany: [],
      listArrays: {
        arraySubStatus: [],
        arrayDeliveryStatus: [],
        arraySubjects: [],
        arrayCountries: []
      },
      loading: {
        first: false,
        exportFile: false
      },
      tagFilter: {
        value: 'all',
        label: 'Todos'},
      ticketIdValue: null,
      optionsFilterSpecial: [
        {text: 'Todos', value: 'all'},
        {text: 'Gestionados', value: 'managed'},
        {text: 'Pendientes documentación', value: 'pendings_doc'},
        {text: 'Pendientes información', value: 'pendings_info'}
      ],
      optionsListTicket: [
        {text: 'Modelo Seller', value: 'modeSeller'},
        {text: 'Modelo Admin', value: 'modeAdmin'},
        {text: 'Modelo Indemnizaciones', value: 'modeCompensation', idModal: 'idModalCompensationMode'}
      ],
      optionsAccounts: [
        { id: 'own_accounts', text: 'Envíame'},
        { id: 'others_accounts', text: 'Empresas'}
      ],
      optionsTicketType: [
        { id: 'ticket_mode_delivery', text: 'Envío'},
        { id: 'ticket_mode_return', text: 'Devolución'}
      ],
      originsOfChanges: [
        { text: 'Manual por plataforma', id: 'platform'},
        { text: 'Reglas de tickets', id: 'ticket-rules'},
        { text: 'Usuarios finales o externos', id: 'external-users'},
        { text: 'Carga masiva', id: 'bulk-load'}
      ],
      optionsStageCode: [
        { text: 'Problema', id: 'ISSUE'},
        { text: 'Reclamo', id: 'COMPLAINT'},
        { text: 'Indemnización', id: 'COMPENSATION'},
        { text: 'Discrepancia', id: 'DISAGREEMENT'},
        { text: 'Cerrado', id: 'CLOSED'}
      ],
      colorArray: [],
      optionRead: [
        {
          id: 'read',
          code: true,
          text: 'Leído'
        },
        {
          id: 'noRead',
          code: false,
          text: 'No Leído'
        }
      ]
    }
  },
  watch: {
    shownOverlay: {
      handler(value) {
        for (const [key, element] of Object.entries(value)) {
          if (!!element) {
            this.getAllData(key, false)
          }
        }
      },
      deep: true
    }
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    optionsColorArray() {
      return this.colorArray.map(el => {
        return {...el, id: el.id, text: el.text && el.text !== '' ? el.text : '[Sin nombre]', icon: 'CircleIcon', colorIcon: `#${el.colorRGB}` }
      })
    }
  },
  mounted() {
    this.checkConditionUser()
  },
  methods: {
    checkConditionUser() {
      if (!!this.dataUser) {
        this.getFilterData()
        this.getAllData()
      } else {
        setTimeout(() => {
          this.$router.go(this.$router.currentRoute)
        }, 1000)
      }
    },
    exportFile(source) {
      this.loading.exportFile = true
      const queryParams = this.getExportParams(source)
      queryParams.ticket_kanban_type = this.tagFilter.value
      const params = {}
      this.baseService.callService('exportTickets', queryParams, params)
        .then(response => {
          setTimeout(() => {
            window.open(response.data.url, '_blank')
          }, 2000)
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(end => {
          this.loading.exportFile = false
          this.hideModals()
        })
    },
    exportAdminMode(data) {
      this.form = {...this.form, ...data}
      this.exportFile('modeCompensation')
    },
    hideModals() {
      this.$bvModal.hide('idModalCompensationMode')
    },
    filterOptions(value, label) {
      this.tagFilter = {
        value,
        label
      }
      this.getAllData('', true)
    },
    getExportParams(source) {
      let queryParams = {...this.setQueryParamsForm(), ...this.setQueryParamsCompany()}

      switch (source) {
      case 'modeCompensation':
        queryParams = {
          ...queryParams,
          current_status: 'COMPENSATION_ACCEPTED,DISAGREEMENT_COMPENSATION_ACCEPTED',
          excel_model: 'adminCompensation',
          filter_name: 'Indemnizaciones aceptadas'
        }
        break
      case 'modeAdmin':
        queryParams = {
          ...queryParams,
          excel_model: 'admin',
          filter_name: 'Model Admin'
        }
        break
      default:
        queryParams = {...queryParams,
          excel_model: 'default',
          filter_name: 'Resumen Todos'
        }
        break
      }
      return queryParams
    },
    getFilterData() {
      const arrServices = [
        { name: 'getSubState' },
        { name: 'getTrackingStatusesToronto'},
        { name: 'getTicketSubjects'},
        { name: 'getCountriesToronto'},
        { name: 'getColorsTagsByUser'}
      ]
      this.baseService.callMultipleServices(arrServices, true)
        .then(response => {
          this.setColorArray(response.getColorsTagsByUser.data)
          this.listArrays = {
            arraySubStatus: this.setArray(response.getSubState.data),
            arrayDeliveryStatus: this.setArray(response.getTrackingStatusesToronto.data),
            arraySubjects: this.setArray(response.getTicketSubjects.data),
            arrayCountries: this.setArray(response.getCountriesToronto.data)
          }
        })
        .finally(() => this.setFormData())
    },
    getAllData(source = '', isButton = false) {
      if (source === '') {
        this.shownOverlay = {
          closed: true,
          compensation: true,
          complaint: true,
          disagreement: true,
          issue: true
        }
      }
      if (!this.loading.first) {
        this.loading.first = true
        if (isButton && source !== '') this.listTickets[source] = []
        const queryParams = this.setQueryParams(source, isButton)
        this.baseService.callService('getTicketsKanban', queryParams, {})
          .then(response => {
            if (source !== '') {
              if (response.data[source].length === 0) {
                this.page[source] -= 1
              }
              this.listTickets[source] = [...this.listTickets[source], ...response.data[source]]
            } else {
              this.listTickets = response.data
            }
          })
          .finally(() => {
            this.loading.first = false
            this.shownOverlay = {
              closed: false,
              compensation: false,
              complaint: false,
              disagreement: false,
              issue: false
            }
          })
      }
    },
    resetForm() {
      this.tagFilter = {
        value: 'all',
        label: 'Todos'
      }
      this.arrayCarrier = []
      this.arrayCompany = []
      this.form = {}
      this.getAllData('', true)
    },
    setColorArray(data) {
      this.colorArray = data.map((el) => ({
        ...el,
        id: el.colorId,
        text: el.colorTagText
      }))
      if (this.fieldsFormAdvanced.length > 0) {
        this.fieldsFormAdvanced.map(el => {
          if (el.name === 'colorId') {
            el.options = [...this.optionsColorArray.filter(el => el.colorId > 0)]
          }
        })
      }
      this.keyFormRender++
    },
    setFormData() {
      const containerClass = {
        fourColumns: 'col-sm-12 container-info col-md-3',
        threeColumns: 'col-sm-12 container-info col-md-4'
      }
      const fieldsFormConst = [
        { id: 0, fieldType: 'FieldDatepicker', name: 'last_message', label: 'Fecha último mensaje', containerClass: containerClass.fourColumns, clearable: true, range: true},
        { id: 1, fieldType: 'FieldSelect', name: 'current_status', label: 'Estado del ticket', containerClass: containerClass.fourColumns, options: this.listArrays.arraySubStatus, searchOnType: {nChars: 999}, multiple: true },
        { id: 2, fieldType: 'FieldInput', name: 'tickets_or_deliveries', label: 'Nº de OT o Referencia', type: 'text', containerClass: containerClass.fourColumns},
        { id: 3, fieldType: 'FieldSelect', name: 'delivery_status_code', label: 'Estado del envío', containerClass: containerClass.fourColumns, options: this.listArrays.arrayDeliveryStatus, searchOnType: {nChars: 999} },
        { id: 4, fieldType: 'FieldSelect', name: 'carrier', label: 'Courier', containerClass: containerClass.fourColumns, options: this.arrayCarrier, searchOnType: {fx: this.searchCarrier, nChars: 3} },
        { id: 5, fieldType: 'FieldSelect', name: 'ticket_type_code', label: 'Asunto del ticket', containerClass: containerClass.fourColumns, options: this.listArrays.arraySubjects, searchOnType: {nChars: 999} },
        { id: 6, fieldType: 'FieldSelect', name: 'company_code', label: 'Cliente', containerClass: containerClass.fourColumns, options: this.arrayCompany, searchOnType: {fx: this.searchCompany, nChars: 3} },
        { id: 7, fieldType: 'FieldCheckbox', name: 'account_owner', label: 'Propietario de cuentas', align: 'h', containerClass: containerClass.fourColumns, options: this.optionsAccounts, multiple: true},
        { id: 8, fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: containerClass.fourColumns, options: this.listArrays.arrayCountries, searchOnType: {nChars: 999}, addFlags: true },
        { id: 9, fieldType: 'FieldCheckbox', name: 'tickets_mode', label: 'Tipo de ticket', align: 'h', containerClass: containerClass.fourColumns, options: this.optionsTicketType, multiple: true},
        { id: 10, fieldType: 'FieldSelect', name: 'origins_of_changes', label: 'Origen de datos', containerClass: containerClass.fourColumns, options: this.originsOfChanges, searchOnType: {nChars: 999} },
        { id: 11, fieldType: 'FieldSelect', name: 'current_stage', label: 'Etapa del ticket', containerClass: containerClass.fourColumns, options: this.optionsStageCode, searchOnType: {nChars: 999} },
        { id: 12, fieldType: 'FieldSelect', name: 'colorId', label: 'Etiquetas de color del ticket', containerClass: containerClass.fourColumns, options: this.optionsColorArray.filter(el => el.id !== 0), multiple: true, searchOnType: {nChars: 999}, elementIcon: true},
        { id: 13, fieldType: 'FieldSelect', name: 'read', label: 'Estado de lectura del ticket', containerClass: containerClass.fourColumns, options: this.optionRead, searchOnType: {nChars: 999} }
      ]
      this.fieldsForm = [
        {...fieldsFormConst[0]},
        {...fieldsFormConst[1]},
        {name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12 col-md-1'},
        {name: 'advancedSearchSlot', useSlot:true, containerClass: 'col-12 col-md-2'},
        {name: 'secondButtonsGroupSlot', useSlot:true, containerClass: 'col-12 col-md-3'}
      ]
      
      this.fieldsFormAdvanced = this.dataUser.role === 'admin' ? [
        {...fieldsFormConst[0], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[2], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[3], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[1], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[4], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[5], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[6], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[7], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[8], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[9], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[10], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[11], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[12], containerClass: containerClass.threeColumns}
      ] : [
        {...fieldsFormConst[0], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[2], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[9], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[1], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[5], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[10], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[12], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[4], containerClass: containerClass.threeColumns},
        {...fieldsFormConst[3], containerClass: containerClass.threeColumns}
      ]
      this.setDefaultValues()
    },
    setArray(arrayValues = []) {
      return arrayValues.map(el => ({
        id: el.id,
        text: el.name ?? el.complete_name,
        code: el.code
      }))
    },
    setDefaultValues() {

    },
    searchCarrier (value) {
      const params = {}
      const queryParams = {
        name_sub_string: value
      }
      return this.baseService.callService('getCarrierSearch', queryParams, params)
        .then(response => {
          const carrier = response.data ? response.data.map(el => ({
            id: el.id,
            text: el.name,
            code: el.code
          })) : []
          return carrier
        })
        .catch(err => {
          console.error(err)
          return []
        })
    },
    searchCompany(value) {
      const queryParams = {
        search_by: value,
        calls: 1
      }
      return this.baseService.callService('getCompaniesListData', queryParams)
        .then(({data}) => {
          const noMKPData = data.companies.filter(data => !data.marketplace).map(company => {
            return {
              id: company.code,
              text: company.name1
            }
          })
          return noMKPData
        })
    },
    setQueryParams (source = '', isButton = false) {
      const queryParams = {...this.setQueryParamsForm()}
      if (this.tagFilter.value !== 'all') queryParams.ticket_kanban_type = this.tagFilter.value
      if (source !== '') {
        queryParams.current_stage = source.toUpperCase()
        if (isButton) {
          this.page[source] = 1
        } else {
          this.page[source] += 1
        }
      }
      queryParams.page = source !== '' ? this.page[source] : 1

      return queryParams
    },
    setQueryParamsForm() {
      const queryParams = {}
      if (this.form && !this.form.last_message) {
        const defaultMonthStart = new Date()
        defaultMonthStart.setMonth(defaultMonthStart.getMonth() - 2)
        this.form.last_message = {
          start: defaultMonthStart,
          end: new Date()
        }
      }
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          const element = this.form[key]
          const conditionEmpty = Array.isArray(element) ? element.length > 0 : element !== ''
          const conditionDateRange = key === 'last_message'
          const conditionGetData = key !== 'account_owner' && key !== 'tickets_mode'
          if (conditionGetData && conditionEmpty && !conditionDateRange) {
            queryParams[key] = this.getDataForm(key, element)
          } else if (conditionDateRange && conditionEmpty) {
            const formDateMessage = this.getDataForm(key, element)
            queryParams['last_message_from'] = formDateMessage.start
            queryParams['last_message_to'] = formDateMessage.end
          } else if (conditionEmpty) {
            element.forEach(item => {
              queryParams[item.id] = true
            })
          }
        }
      }
      return queryParams
    },
    getDataForm(key, value) {
      const today = new Date()
      let start = ''
      let end = ''
      if (value) {
        switch (key) {
        case 'tickets_or_deliveries':
          return value
        case 'last_message':
          if (value.start && value.end) {
            start = this.$options.filters.moment(value.start, 'YYYY-MM-DD')
            end = this.$options.filters.moment(value.end, 'YYYY-MM-DD')
          } else {
            start = this.$options.filters.moment(value, 'YYYY-MM-DD')
            end = this.$options.filters.moment(today, 'YYYY-MM-DD')
          }
          return {start, end}
        case 'current_status':
          return value.map(el => el.code).join(',')
        case 'colorId':
          return value.map(el => el.id).toString()
        default:
          return value.code ?? value.id
        }
      } else {
        return null
      }
    }, 
    setQueryParamsCompany() {
      const queryParams = {}
      switch (this.dataUser.role) {
      case 'ecommerce':
      case 'seller':
        queryParams.company = this.dataUser.organization.code ?? ''
        break
      case 'marketplace':
        queryParams.marketplace = this.dataUser.organization.code ?? ''
        break
      }
      return queryParams
    },
    setQueryParamsUser() {
      const queryParams = {}
      queryParams.email = this.dataUser.email
      return queryParams
    },
    showTicket(data) {
      this.ticketIdValue = data
    },
    resetTicketId() {
      this.ticketIdValue = null
    }
  }
}
</script>
<style lang="scss" slot-scope>
  .ticket--column--container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;

    .ticket--column {
      background: #F3F3F4;
      padding: 10px;
    }

    .ticket--column--list-button {
      text-align: left;
      font-size: 15px;
      div {
        border-bottom: 2px solid #044389;
        padding: 0 15px;
      }
    }
  }
  .button--head {
    margin-bottom: 13px;
  }
</style>
<style lang="scss">
  .list-tickets-container {
    .tickets-list--buttons {
      display: grid;
      grid-auto-rows: 19.2px auto;
      height: 38px;
      grid-template-rows: max-content;

      &--container {
        display: flex;
        gap: 10px;

        & button {
          padding: 0 10px;
          height: 38px;
        }

        & .dropdown-toggle::after {
          left: 0;
        }
      }

      &--link {
        padding: 0;
        text-decoration: underline;
      }
    }
  }
</style>
<template lang="">
  <b-modal id="ModalTicketID" 
    @close="close" 
    size="xl" hide-footer 
    body-class="p-0" centered
    header-class="modalTicketIdClass">
    <detail-ticket-vue v-if="!!ticketId" :ticketIdModal="ticketId"></detail-ticket-vue>
  </b-modal>
</template>
<script>
import DetailTicketVue from '@/views/modules/tickets/components/Detail/DetailTicket.vue'
export default {
  props: ['ticketId'],
  components: {DetailTicketVue},
  data() {
    return {
      viewDetail: false
    }
  },
  methods: {
    close() {
      this.$emit('resetTicketId', true)
    }
  }
}
</script>
<style lang="scss">
  .modal .modalTicketIdClass {
    margin-bottom: -51px;
    z-index: 10;
    background: transparent;
  }
</style>
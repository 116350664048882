<template>
  <section>
    <div>
      <b-button id="popover-reactive-1" variant="outline-primary" ref="button">
        <icons :icon="'Eyedropper'"></icons>
      </b-button>
    </div>
    <b-popover
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="bottomleft"
      container="my-container"
      @show="onShow"
      ref="popover"
      custom-class="popover--container"
    >
      <template #title>
        Personaliza el seguimiento de tus tickets asignándoles etiquetas de color
        <b-button @click="onClose" aria-label="Close" variant="link" class="button-close">
          <feather-icon size="1.5x" icon="XIcon"/>
        </b-button>
      </template>
      <section>
        <p>
          Elige entre cinco colores predefinidos que puedes nombrar según tus necesidades. Utiliza estas etiquetas en las tarjetas para mantener un seguimiento detallado de cada incidencia en sus distintas etapas. Recuerda que estas etiquetas no afectan la priorización de tus tickets.
        </p>
        <b-form @submit="saveColors" v-if="colorList">
          <div v-for="(color, index) in colorList" :key="index" class="item--form-container">
            <b-icon :style="{color: `#${color.colorRGB}`}" icon="circle-fill" font-scale="2"></b-icon>
            <b-input-group class="input--group" :class="loading || color.colorId === 0 ? 'disabled' : ''">
              <b-form-input
                v-model="form[color.colorId]"
                type="text"
                placeholder="Ingrese nombre de etiqueta"
                clearable="true"
                class="input--form"
                :disabled="loading || color.colorId === 0"
              >
              </b-form-input>
              <!-- <template #append>
                <b-button :disabled="color.colorId === 0" variant="link" class="button--append" @click="resetInput(color)"><feather-icon icon="XIcon"/></b-button>
              </template> -->
              <b-input-group-append class="clearable" :disabled="color.colorId === 0">
                <b-button :disabled="color.colorId === 0" variant="link" class="button--append" @click="resetInput(color)"><feather-icon icon="XIcon"/></b-button>
              </b-input-group-append>
            </b-input-group>
            
          </div>
          <div class="d-flex justify-content-end mt-2 mb-2">
            <b-button type="submit" size="sm" variant="warning" :disabled="loading">Guardar</b-button>
          </div>
        </b-form>
      </section>
    </b-popover>
  </section>
</template>

<script>
import Icons from '@/Icons/Icons.vue'
import {
  BIcon,
  BIconEyeglasses,
  BIconCircleFill
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
import BaseServices from '@/store/services/index'
export default {
  props: ['colorArray'],
  components: {BIcon, BIconEyeglasses, BIconCircleFill, Icons},
  data() {
    return {
      baseService: new BaseServices(this),
      popoverShow: false,
      form: {},
      loading: false
    }
  },
  computed: {
    colorList() {
      return this.colorArray ? [...this.colorArray] : []
    }
  },
  methods: {
    onClose() {
      this.popoverShow = false
      this.loading = false
    },
    saveColors(e) {
      e.preventDefault()
      this.loading = true
      const data = {
        color_tags : this.convertForm()
      }
      this.baseService.callService('postColorsTagsByUser', data, {})
        .then(response => {
          this.$emit('updateColorUser', response.data)
          this.onClose()
          this.$success('Color actualizado correctamente')
        })
        .catch(err => {
          this.$error('Ha ocurrido un error')
          console.error(err)
        })
    },
    convertForm() {
      let response = []
      for (const key in this.form) {
        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
          const element = this.form[key]
          if (key !== '0') {
            response = [...response, {
              color_id: parseInt(key),
              color_tag_text: element
            }]
          }
        }
      }
      return response 
    },
    onShow() {
      if (this.colorList.length > 0) {
        this.setForm()
      } else {
        setTimeout(() => {
          this.onShow()
        }, 1000)
      }
    },
    resetInput(data) {
      this.form[data.colorId] = ''
    },
    setForm() {
      this.form = this.colorList.reduce(
        (obj, item) => Object.assign(obj, { [item.colorId]: item.colorTagText }), {})
    }
  }
}
</script>
<style lang="scss">
  .popover--container {
    min-width: 750px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.63px;
    text-align: left;

    .popover-header {
      background: white;
      color: #044389;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.29px;
      border: none;
      text-decoration: underline;
    }
    .button-close {
      height: auto;
      padding: 0;
      float: right;
    }
    .item--form-container {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 5px 0;

      .input--group {
        border: 1px solid #d8d6de;
        border-radius: 0.357rem;
        &:hover {
          border-color: #044389;
        }
        &.disabled {
          border-color: #efefef;

          .input-group-append {
            background: #efefef;
          }
        }
      }
      .input--form {
        border: 0;
      }
      .button--append {
        color: unset;
      }
      .button--append:hover {
        color: #044389;
      }
    }
  }
</style>
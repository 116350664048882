<template>
  <b-modal id="idModalUpdateDocuments"
    size="lg"
    title="Subir Archivo" modal-class="modal-idModalUpdateDocuments"
    hide-footer>
    <b-tabs>
      <b-tab>
        <template #title><div>Documentos</div></template>
        <update-document></update-document>
      </b-tab>
      <b-tab>
        <template #title><div>Instrucciones</div></template>
        <update-instructions></update-instructions>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import updateDocument from '@/views/modules/tickets/components/updateDocuments/updateDocument.vue'
import updateInstructions from '@/views/modules/tickets/components/updateDocuments/updateInstructions.vue'

export default {
  components: {updateDocument, updateInstructions}
}
</script>
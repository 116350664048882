<template>
  <b-modal id="idModalCompensationMode" size="sm" hide-footer centered title="Datos adicionales exportación" @show="show">
    <form-render :form.sync="form" :fields="fields" @send="onAccept">
      <template #ufSlot>
          <b-form-input v-model="form.uf_value" type="number" required ></b-form-input>
      </template>
      <template #buttonSlot>
        <div class="d-flex justify-content-end w-100">
          <b-button type="submit" variant="outline-success" :disabled="loading">
            <b-icon icon="file-arrow-down" ></b-icon> Descargar
          </b-button>
        </div>
      </template>
    </form-render>
  </b-modal>
</template>
<script>
import {
  BIcon,
  BIconFileArrowDown
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  components: {BIcon, BIconFileArrowDown},
  props: ['loadingButton'],
  data() {
    return {
      form: {},
      fields: [
        {name: 'ufSlot', label: 'Valor de la UF', useSlot: true, containerClass: 'col-sm-12 container-info'},
        {name: 'buttonSlot', useSlot: true, containerClass: 'col-sm-12 container-info'}
      ]
    }
  },
  computed: {
    loading() {
      return !!this.loadingButton
    }
  },
  methods: {
    formatCurrency(d) {
      return this.$options.filters.currency(d, 0, '$')
    },
    onAccept() {
      this.$emit('exportAdminMode', this.form)
    },
    show() {
      this.form = {}
    }
  }
}
</script>